import { Box, Button, Center, chakra, Flex } from '@chakra-ui/react'
import { graphql } from 'gatsby'
import React from 'react'
import { SectionBlock } from 'starterUiComponents'
import clsx from 'clsx'


export const fragment = graphql`
  fragment seminarBlockFragment on WpPage_Flexlayouts_FlexibleLayouts_SeminarBlock {
    title
    subtitle
    yellowText
    singleSelections {
      mBookSet
      mNoBookSet
      nbBookSet
      nbNoBookSet
      day
      date
      month
      time
    }

    contentBelow
    cssclass
    anchor
    seminarPrice
  }
`
const Separator = (props) => (
  <div className="w-full my-6 border border-border" {...props} />
)
export const SeminarBlock = ({
  title,
  subtitle,
  yellowText,
  singleSelections,
  seminarPrice,
  contentBelow,
  cssclass,
  anchor,
  
  ...props
}) => {

  const hasMultipleEntries = singleSelections?.length > 1;

  return (
    <SectionBlock anchor={anchor} cssclass={cssclass} {...props}>
      <div className="bg-brown rounded-twenty px-7 md:px-20 py-14 my-14">
        <div className="text-center">
          <chakra.h2
            className="text-xl font-light text-white uppercase md:text-xxl"
            sx={{ strong: { color: 'yellow' } }}
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <h3
            className="text-white uppercase text-l"
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />
          <div
            className="font-medium text-yellow"
            dangerouslySetInnerHTML={{ __html: yellowText }}
          />
        </div>
        <Separator />
        <div className={clsx(
          'gap-6 py-4 md:py-12',
          {
            'grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3': hasMultipleEntries,
            'flex justify-center': !hasMultipleEntries
          }
        )}>
          {singleSelections?.map((item, i) => {            
            const { day, date, month, time } = item
            return (
              <div key={i} className="block max-w-fit text-white lg:flex">
                <Flex
                  // className={clsx('items-center justify-center ', {
                  //   'md:justify-start': hasMultipleEntries,
                  // })}
                  className='items-center justify-center md:justify-start'
                  // sx={{
                  //   maxWidth: ['300px'],
                  //   justifyContent: ['start', 'start', 'space-between'],
                  //   display: ['block', 'block', 'block', 'flex'],
                  //   mt: [10, 10, 10, 0],
                  // }}
                >
                  <Center
                    w="100px"
                    h="98px"
                    className="py-5 mr-5 text-center text-white bg-lightBlue rounded-twenty"
                  >
                    <div>
                      <div className="text-xl font-bold leading-10 font-heading">
                        {day}
                      </div>
                      <div className="font-bold uppercase text-l font-heading">
                        {month}
                      </div>
                    </div>
                  </Center>
                  <div className="my-2 lg:my-0">
                    {hasMultipleEntries && <h4 className="text-white uppercase text-l">
                      Part {i + 1}: 
                    </h4>}
                    <div className="text-base font-medium">
                      <div>{date}</div>
                      <div>{time}</div>
                    </div>
                  </div>
                </Flex>
              </div>
            )
          })}
        </div>
        <Separator />
        <div className="items-center justify-center block lg:flex">
          <div>
            <Button
              as="a"
              href="https://atsg.link/seminar25"
              target="_blank"
              variant="outlineWhite"
              className="mx-auto lg:mx-0"
            >
              Register Online
            </Button>
            <Box
              className="mt-5 font-medium text-center text-white"
              sx={{ a: { fontWeight: 500 } }}
            >
              Or call <a href="tel:(800)245-7722x1113">(800)245-7722x1113</a>
            </Box>
          </div>
        </div>
      </div>

      <div
        dangerouslySetInnerHTML={{ __html: contentBelow }}
        className="w-full"
      />

      {/* <h3 className="mb-6 uppercase text-md">
        Each part will follow the format BELOW using different material and
        bonus topics:
      </h3>
      <div className="justify-between block w-1/2 mb-6 font-medium lg:flex">
        <div>Red Book: GM - Medium Duty Trucks</div>
        <div>
          <div>1 Hour Presentation</div>
          <div>20 min Q&A</div>
        </div>
      </div>
      <div className="justify-between block w-1/2 mb-6 font-medium lg:flex">
        <div>White Book: Ford - Chrysler</div>
        <div>
          <div>1 Hour Presentation</div>
          <div>20 min Q&A</div>
        </div>
      </div>
      <div className="justify-between block w-1/2 mb-6 font-medium lg:flex">
        <div>Blue Book: Imports </div>
        <div>
          <div>1 Hour Presentation</div>
          <div>20 min Q&A</div>
        </div>
      </div> */}
    </SectionBlock>
  )
}
